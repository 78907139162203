.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.sidebar {
  display: none;
}

.mainContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1.6rem auto 0 auto;
  width: 34.3rem;
}

.pageTitle {
  color: var(--color-gray-900);
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.8rem;
  width: 34.3rem;
}

.dealsSummary {
  color: var(--color-gray-500);
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  gap: 8px;
  line-height: 2.8rem;
  margin-top: 1.2rem;
  width: 34.3rem;
}

.footer {
  min-height: 100vh;
  width: 100vw;
}

@media screen and (min-width: 415px) {
  .pageContainer {
    display: grid;
    grid-template: 10rem auto 5rem / auto 1fr;
    grid-template-areas:
      'sidebar header'
      'sidebar  main'
      'sidebar footer';
    height: 100vh;
    width: auto;
  }
  .sidebar {
    display: block;
    grid-area: sidebar;
  }
  .topbar {
    grid-area: header;
  }
  .mainContent {
    grid-area: main;
    width: 64.3rem;
  }
  .pageTitle {
    font-size: 3.6rem;
    width: 64.3rem;
  }
  .dealsSummary {
    width: 64.3rem;
  }
  .footer {
    grid-area: footer;
    min-height: auto;
    width: 100%;
  }
}
