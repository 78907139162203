.container {
  align-items: center;
  background-color: var(--color-accent-white);
  display: flex;
  gap: 1.6rem;
  height: 7.2rem;
  justify-content: space-evenly;
  padding: 1.6rem;
  width: 100%;
}

.buttonContainer {
  align-items: center;
  background-color: var(--color-blue-gray-500);
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  gap: 8px;
  height: 4rem;
  justify-content: center;
  padding: 1rem 1.8rem;
  width: 13.2rem;
}

button {
  background: none;
  border: none;
  color: var(--color-accent-white);
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 600;
  height: 2rem;
  line-height: 2rem;
  width: 7.2rem;
}
