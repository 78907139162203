.container {
  display: none;
}

@media screen and (min-width: 415px) {
  .container {
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    cursor: pointer;
    display: flex;
    font-weight: 600;
    gap: 1.2rem;
    height: 5.6rem;
    max-width: 32rem;
    padding: 1.2rem 2.4rem;
    width: fit-content;
  }
}
