.logo {
  height: 7.5rem;
  margin-top: 5rem;
  padding: 1.6rem;
  width: 20.1rem;
}

.leftContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
}

.rightContainer {
  display: none;
}

.leftContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.1rem;
  height: 100%;
  justify-content: center;
  padding: 0 4.8rem;
}

.formContainer {
  align-items: center;
  background-color: var(--color-accent-white);
  border-radius: 12px;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  height: 38rem;
  gap: 2rem;
  left: 50%;
  padding: 5rem 1.6rem;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 34.3rem;
}

.labels {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 16rem;
  width: 31.1rem;
}

.formContainer span {
  color: var(--color-gray-700);
  display: block;
  margin-bottom: 0.6rem;
}

.formContainer input {
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  color: var(--color-gray-400);
  font-size: 1.6rem;
  height: 4.4rem;
  outline: 0;
  padding: 1rem 1.4rem;
  transition: border 0.15s, box-shadow 0.15s;
  width: 31.1rem;
}

.formContainer input:focus {
  border: 1px solid var(--color-blue-gray-500);
  box-shadow: 0 0 0 5px var(--color-accent);
}

.formContainer input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-gray-400);
  font-size: 1.6rem;
  opacity: 1; /* Firefox */
}

.forgotPassword {
  text-align: right;
  width: 100%;
}

.forgotPassword span {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
}

.btn {
  background-color: var(--color-blue-gray-500);
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-accent-white);
  cursor: pointer;
  font-size: 1.6rem;
  height: 4.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.8rem;
  width: 100%;
}

.copyright {
  color: var(--color-gray-800);
  font-size: 1.2rem;
  font-weight: 500;
}

.copyright a {
  color: var(--color-gray-800);
  text-decoration: none;
  margin-left: 5px;
}

.copyright a:hover {
  color: var(--color-blue-gray-300);
}

.alerts {
  margin-top: 0.7rem;
}

@media screen and (min-width: 1025px) {
  .logo {
    margin-top: 3rem;
  }
  .formContainer {
    height: 45rem;
    justify-content: space-around;
    width: 48rem;
  }
  .labels {
    width: 100%;
  }
  .formContainer input {
    width: 100%;
  }
  .forgotPassword span {
    font-size: 1.4rem;
  }
  .pageContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'leftContainer rightContainer';
    height: 100vh;
  }
  .leftContainer {
    grid-area: leftContainer;
  }
  .rightContainer {
    background: linear-gradient(
      180deg,
      #3e4784 13.54%,
      #48508a 32.29%,
      #c6c7dd 100%
    );
    display: block;
    grid-area: rightContainer;
  }
  .rightContent {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .title {
    color: var(--color-accent-white);
    font-size: 3rem;
    font-weight: 600;
    height: 7.6rem;
    line-height: 3.8rem;
    margin-top: 9.8rem;
    width: 48.9rem;
  }
  .description {
    color: var(--color-accent-white);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin-top: 2.1rem;
    width: 48.9rem;
  }
  .leftContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2.1rem;
    height: 100%;
    justify-content: center;
    padding: 0 4.8rem;
  }
  .toolbar {
    height: 1.856rem;
    margin-top: 8.5rem;
    width: 57.121rem;
  }
  .mockup {
    border-radius: 4.28px;
    height: 37.129rem;
    margin-top: -0.1rem;
    width: 57.121rem;
  }
  .copyright {
    font-size: 1.4rem;
  }
  .alerts {
    margin-top: 1rem;
  }
}
