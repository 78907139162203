footer {
  align-items: center;
  background-color: var(--color-gray-200);
  bottom: 0;
  display: flex;
  gap: 10px;
  height: 3.9rem;
  justify-content: center;
  left: 0;
  padding: 1.2rem 2.4rem;
  position: fixed;
  width: 100%;
}

.copyright {
  color: var(--color-gray-800);
  font-size: 1.2rem;
  font-weight: 500;
}

.copyright a {
  color: var(--color-gray-800);
  text-decoration: none;
  margin-left: 5px;
}

.copyright a:hover {
  color: var(--color-blue-gray-300);
}

@media screen and (min-width: 768px) {
  footer {
    height: 4.1rem;
  }
  .copyright {
    font-size: 1.4rem;
  }
}
