.modalBackdrop {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.modal {
  background: var(--color-accent-white);
  border-radius: 1.2rem;
  height: 100vh;
  left: 50%;
  padding: 3rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
}

.modalContent {
  height: fit-content;
  overflow-y: auto;
}

.iconContainer {
  align-items: center;
  border-bottom: 1px solid var(--color-gray-100);
  cursor: pointer;
  display: flex;
  height: 5.6rem;
  justify-content: space-between;
  margin-bottom: 3rem;
  width: 100%;
}

.title {
  color: var(--color-gray-800);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.1rem;
}

/* Smaller screens */

@media screen and (max-width: 415px) and (max-height: 680px) {
  .modalContent {
    height: 50rem;
  }
}

/* Larger Screens */

@media screen and (min-width: 415px) and (min-height: 680px) {
  .modal {
    width: 50.3rem;
    height: fit-content;
  }
  .modalContent {
    height: fit-content;
    max-width: 50.3rem;
  }
}

@media screen and (min-width: 415px) and (max-height: 680px) {
  .modal {
    width: 50.3rem;
    height: fit-content;
  }
  .modalContent {
    height: 50rem;
    max-width: 50.3rem;
  }
}
