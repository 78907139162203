.container {
  width: 28.6rem;
  min-height: fit-content;
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 1.6rem;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.stageInfo {
  display: flex;
  gap: 0.6rem;
  align-items: center;
}

.stageName {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;
  color: var(--color-gray-900);
}

.quantity {
  padding: 0.2rem 0.4rem;
  width: 1.9rem;
  height: 1.9rem;
  background-color: var(--color-gray-300);
  border-radius: 3.4rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.452rem;
  color: var(--color-gray-700);
  display: flex;
  align-items: center;
  justify-content: center;
}

.total {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--color-gray-500);
}

.total span {
  font-weight: 600;
}

.cardGood,
.cardInProgress,
.cardDeclined {
  width: 25.4rem;
  height: 15.1rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  gap: 1.2rem;
  background-color: var(--color-accent-white);
  border-radius: 0.6rem;
}

.cardGood {
  border-top: 3px solid var(--color-success-500);
}

.cardInProgress {
  border-top: 3px solid var(--color-blue-gray-400);
}

.cardDeclined {
  border-top: 3px solid var(--color-accent-red);
}

.cardTop {
  display: flex;
  flex-direction: column;
}

.dealName {
  width: 100%;
  height: 2.4rem;
  color: var(--color-gray-900);
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.2rem;
}

.dealClient {
  width: 100%;
  height: 3.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--color-gray-500);
  margin-bottom: 1.2rem;
}

.cardBottom {
  display: flex;
  height: 3.1rem;
  width: 100%;
  justify-content: space-between;
  gap: 1.2rem;
  align-items: center;
}

.dealValue {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.inProgress,
.success,
.declined {
  align-items: center;
  border-radius: 1.2rem;
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  height: 3.1rem;
  justify-content: center;
  line-height: 1.452rem;
  padding: 0.8rem 1.2rem;
  width: 9.9rem;
}

.inProgress {
  background-color: var(--color-gray-100);
  color: var(--color-gray-500);
}

.success {
  background-color: var(--color-success-100);
  color: var(--color-success-500);
}

.declined {
  background-color: var(--color-error-100);
  color: var(--color-accent-red);
}

@media screen and (min-width: 415px) {
  .container {
    gap: 2.4rem;
    padding: 2.4rem;
    width: 30.2rem;
  }
}
