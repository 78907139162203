.container {
  display: flex;
  height: auto;
  justify-content: center;
  margin-top: 1.5rem;
  width: 100%;
}

.paginationButtons {
  max-width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.pageElement {
  max-width: 4.2rem;
}

.paginationButtons a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid var(--color-gray-400);
  cursor: pointer;
}

.paginationButtons a:hover {
  color: var(--color-accent-white);
  background-color: var(--color-blue-gray-500);
}

.paginationActive a {
  background-color: var(--color-blue-gray-500);
  color: var(--color-accent-white);
}

.previousButton,
.nextButton {
  background-color: var(--color-blue-gray-500);
  color: var(--color-accent-white);
}

/* Use if necessary */

/* .paginationDisabled a {
  color: var(--color-gray-50);
  background-color: var(--color-gray-50);
  border: none;
} */
