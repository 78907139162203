.container {
  align-items: center;
  background-color: var(--color-accent-white);
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  gap: 8px;
  height: 4rem;
  padding-left: 1rem;
  padding: 1rem 1.4rem;
  width: 20.5rem;
}

.container input {
  border: none;
  box-shadow: none;
  color: var(--color-gray-400);
  width: 15.1rem;
}

.container input:focus {
  border: none;
  outline: 0;
  transition: border 0.15s, box-shadow 0.15s;
}

@media screen and (min-width: 415px) {
  .container {
    width: 56rem;
  }
}
