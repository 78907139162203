.container {
  align-items: center;
  background-color: var(--color-blue-gray-500);
  display: flex;
  gap: 1.6rem;
  height: 7.5rem;
  justify-content: space-between;
  padding: 1.6rem;
  width: 100%;
}

.openedMenu {
  background-color: var(--color-blue-gray-500);
  height: 100vh;
  width: 100vw;
}

.topMenu {
  align-items: center;
  background-color: var(--color-blue-gray-500);
  display: flex;
  gap: 1.6rem;
  height: 7.5rem;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  padding: 1.6rem;
  width: 100%;
}

.menuItems {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: auto;
  padding: 0 1.6rem;
  width: 100%;
}

.linkContainer {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  gap: 1.2rem;
  height: 5.6rem;
  line-height: 2.4rem;
  padding: 1.6rem;
  width: 34.3rem;
}

.linkContainer a {
  color: var(--color-accent-white);
}

.bottomContainer {
  background-color: var(--color-gray-25);
  border-radius: 12px;
  bottom: 4.8rem;
  display: flex;
  flex-direction: column;
  height: 13.5rem;
  justify-content: center;
  left: 50%;
  padding: 1.2rem 2.4rem;
  position: fixed;
  transform: translateX(-50%);
  width: 34.3rem;
}

.userContainer {
  align-items: center;
  color: var(--color-gray-900);
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  gap: 1rem;
  line-height: 2.4rem;
  padding: 0 1.2rem;
}

.signoutContainer {
  align-items: center;
  display: flex;
  gap: 0.6rem;
  padding: 1.2rem;
}

.btn {
  background-color: var(--color-gray-25);
  border: none;
  color: var(--color-accent-red);
}

@media screen and (min-width: 415px) {
  .container {
    display: none;
  }
  .openedMenu {
    display: none;
  }
}
