.container {
  align-items: center;
  background-color: var(--color-blue-gray-600);
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  height: 100%;
  min-height: 100vh;
  width: 22.9rem;
}

.logo {
  margin: 3.2rem 1.4rem;
}

.menuItems {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: auto;
  padding: 0 1.6rem;
  width: 100%;
}
