@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap);
:root {
  /* Gray Colors */
  --color-gray-25: #fcfcfd;
  --color-gray-50: #f9fafb;
  --color-gray-100: #f2f4f7;
  --color-gray-200: #eaecf0;
  --color-gray-300: #d0d5dd;
  --color-gray-400: #98a2b3;
  --color-gray-500: #667085;
  --color-gray-600: #475467;
  --color-gray-700: #344054;
  --color-gray-800: #1d2939;
  --color-gray-900: #101828;
  /* Blue Gray Colors */
  --color-blue-gray-25: #fcfcfd;
  --color-blue-gray-50: #f8f9fc;
  --color-blue-gray-100: #eaecf5;
  --color-blue-gray-200: #d5d9eb;
  --color-blue-gray-300: #afb5d9;
  --color-blue-gray-400: #717bbc;
  --color-blue-gray-500: #4e5ba6;
  --color-blue-gray-600: #3e4784;
  --color-blue-gray-700: #363f72;
  --color-blue-gray-800: #293056;
  --color-blue-gray-900: #101323;
  /* Accent Colors */
  --color-accent-white: #fff;
  --color-accent-black: #000;
  --color-accent-red: #f04438;
  --color-accent-alert: #ffebe9;
  --color-accent-green: #32d583;
  --color-accent-yellow: #f79009;
  /* Other Colors */
  --color-error-100: #fee4e2;
  --color-success-100: #d1fadf;
  --color-success-500: #12b76a;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Typography */

::selection {
  background-color: #f9fafb;
  background-color: var(--color-gray-50);
  color: #344054;
  color: var(--color-gray-700);
}

html {
  font-size: 62.5%;
}

img {
  width: 100%;
}

body {
  background-color: #f9fafb;
  background-color: var(--color-gray-50);
  color: #667085;
  color: var(--color-gray-500);
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}

.main {
  min-height: 100vh;
  width: auto;
}

h1,
h2 {
  color: #101828;
  color: var(--color-gray-900);
}

h3,
h4 {
  color: #667085;
  color: var(--color-gray-500);
}

h1 {
  font-size: 2.4rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.4rem;
  font-weight: 600;
}

h4 {
  font-size: 1.2rem;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  body {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Links */

a {
  text-decoration: none;
}

footer a:hover {
  color: #717bbc;
  color: var(--color-blue-gray-400);
}

/* App */

.App {
  min-height: 100vh;
  width: 100vw;
}

/* Alerts */

.form-alert,
.form-alert-success {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-weight: 500;
  font-weight: 700;
  height: 6rem;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 2rem;
  width: 100%;
}

.form-alert {
  background-color: #ffebe9;
  background-color: var(--color-accent-alert);
  border: 1px solid #f04438;
  border: 1px solid var(--color-accent-red);
  color: #f04438;
  color: var(--color-accent-red);
}

.form-alert-success {
  background-color: #d1fadf;
  background-color: var(--color-success-100);
  border: 1px solid #12b76a;
  border: 1px solid var(--color-success-500);
  color: #12b76a;
  color: var(--color-success-500);
}

/* Logos */

.logo,
.logo-login {
  align-items: center;
  display: flex;
  font-family: 'Manrope', sans-serif;
  font-size: 1.6rem;
  font-weight: 800;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  height: 4.3rem;
  line-height: 2.8rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.logo {
  color: #fcfcfd;
  color: var(--color-blue-gray-25);
}

.logo-login {
  color: #3e4784;
  color: var(--color-blue-gray-600);
}

.logo a {
  color: #fcfcfd;
  color: var(--color-blue-gray-25);
}

.logo-login a {
  color: #3e4784;
  color: var(--color-blue-gray-600);
}

/* Sidebar Nav (Desktop) */

.link-container,
.link-container-active {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  height: 5.6rem;
  line-height: 2.4rem;
  padding: 1.6rem;
  width: 100%;
}

.link-container {
  border: none;
}

.link-container-active {
  border: 2px solid #fff;
  border: 2px solid var(--color-accent-white);
  background-color: #fff;
  background-color: var(--color-accent-white);
  color: #fff;
  color: var(--color-accent-white);
}

.link-container a {
  color: #fff;
  color: var(--color-accent-white);
}

.link-container-active a {
  color: #4e5ba6;
  color: var(--color-blue-gray-500);
}

.Login_logo__39uNP {
  height: 7.5rem;
  margin-top: 5rem;
  padding: 1.6rem;
  width: 20.1rem;
}

.Login_leftContainer__12slk {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
}

.Login_rightContainer__1fRcK {
  display: none;
}

.Login_leftContent__3TJOW {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2.1rem;
  gap: 2.1rem;
  height: 100%;
  justify-content: center;
  padding: 0 4.8rem;
}

.Login_formContainer__2eRE3 {
  align-items: center;
  background-color: var(--color-accent-white);
  border-radius: 12px;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  grid-gap: 2.4rem;
  gap: 2.4rem;
  height: 60.4rem;
  justify-content: center;
  left: 50%;
  padding: 4.8rem 1.6rem;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 34.3rem;
}

.Login_topContainer__1nIF5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1.4rem;
  gap: 1.4rem;
  height: 6.4rem;
  margin-bottom: 1rem;
  padding: 0px;
  width: 31.1rem;
}

.Login_demoNote__3fS3R {
  display: block;
  margin-bottom: 1.2rem;
}

.Login_demoDetails__RDxGz {
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.Login_demoCredentials__nF9p_ {
  background-color: var(--color-blue-gray-50);
  border-radius: 0.8rem;
  color: var(--color-blue-gray-500);
  font-size: 1.4rem;
  font-weight: 400;
  height: 7.8rem;
  line-height: 1.694rem;
  padding: 1.6rem 1.2rem;
  width: 31.1rem;
}
.Login_username__31cB2,
.Login_password__IqnO5 {
  display: flex;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}

.Login_middleContainer__1oZcq {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2.4rem;
  gap: 2.4rem;
  height: 27.2rem;
  padding: 0px;
  width: 31.1rem;
}

.Login_labels__2mRvX {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  height: 16rem;
  width: 31.1rem;
}

.Login_formContainer__2eRE3 span {
  color: var(--color-gray-700);
  display: block;
  margin-bottom: 0.6rem;
}

.Login_formContainer__2eRE3 input {
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  color: var(--color-gray-400);
  font-size: 1.6rem;
  height: 4.4rem;
  outline: 0;
  padding: 1rem 1.4rem;
  transition: border 0.15s, box-shadow 0.15s;
  width: 31.1rem;
}

.Login_formContainer__2eRE3 input:focus {
  border: 1px solid var(--color-blue-gray-500);
  box-shadow: 0 0 0 5px var(--color-accent);
}

.Login_formContainer__2eRE3 input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-gray-400);
  font-size: 1.6rem;
  opacity: 1; /* Firefox */
}

.Login_formContainer__2eRE3 input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-gray-400);
  font-size: 1.6rem;
  opacity: 1; /* Firefox */
}

.Login_forgotPassword__1dA6S {
  text-align: right;
  width: 100%;
}

.Login_forgotPassword__1dA6S span {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
}

.Login_btn__1H5kL {
  background-color: var(--color-blue-gray-500);
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-accent-white);
  cursor: pointer;
  font-size: 1.6rem;
  height: 4.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.8rem;
  width: 31.1rem;
}

.Login_bottomContainer__26sCE {
  height: 2.4rem;
  text-align: center;
  width: 100%;
}

.Login_copyright__33L_P {
  color: var(--color-gray-800);
  font-size: 1.2rem;
  font-weight: 500;
}

.Login_copyright__33L_P a {
  color: var(--color-gray-800);
  text-decoration: none;
  margin-left: 5px;
}

.Login_copyright__33L_P a:hover {
  color: var(--color-blue-gray-300);
}

@media screen and (min-width: 1025px) {
  .Login_logo__39uNP {
    margin-top: 3rem;
  }
  .Login_formContainer__2eRE3 {
    height: 57.7rem;
    width: 48rem;
  }
  .Login_topContainer__1nIF5 {
    width: 43.2rem;
  }
  .Login_demoCredentials__nF9p_ {
    display: flex;
    font-weight: 500;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    height: 4.9rem;
    justify-content: center;
    width: 43.2rem;
  }
  .Login_middleContainer__1oZcq {
    height: 40.8rem;
    width: 43.2rem;
  }
  .Login_labels__2mRvX {
    width: 100%;
  }
  .Login_formContainer__2eRE3 input {
    width: 100%;
  }
  .Login_btn__1H5kL {
    width: 43.2rem;
  }
  .Login_forgotPassword__1dA6S span {
    font-size: 1.4rem;
  }
  .Login_pageContainer__1mPPg {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'leftContainer rightContainer';
    height: 100vh;
  }
  .Login_leftContainer__12slk {
    grid-area: leftContainer;
  }
  .Login_rightContainer__1fRcK {
    background: linear-gradient(
      180deg,
      #3e4784 13.54%,
      #48508a 32.29%,
      #c6c7dd 100%
    );
    display: block;
    grid-area: rightContainer;
  }
  .Login_rightContent__1f8gS {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .Login_title__1S5vb {
    color: var(--color-accent-white);
    font-size: 3rem;
    font-weight: 600;
    height: 7.6rem;
    line-height: 3.8rem;
    margin-top: 9.8rem;
    width: 48.9rem;
  }
  .Login_description__2xM4k {
    color: var(--color-accent-white);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin-top: 2.1rem;
    width: 48.9rem;
  }
  .Login_leftContent__3TJOW {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-gap: 2.1rem;
    gap: 2.1rem;
    height: 100%;
    justify-content: center;
    padding: 0 4.8rem;
  }
  .Login_toolbar__LSD8b {
    height: 1.856rem;
    margin-top: 8.5rem;
    width: 57.121rem;
  }
  .Login_mockup__1Xt7B {
    border-radius: 4.28px;
    height: 37.129rem;
    margin-top: -0.1rem;
    width: 57.121rem;
  }
  .Login_copyright__33L_P {
    font-size: 1.4rem;
  }
}

.Signup_logo__2oZbp {
  height: 7.5rem;
  margin-top: 5rem;
  padding: 1.6rem;
  width: 20.1rem;
}

.Signup_leftContainer__7rKxV {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-top: 5rem;
  width: 100%;
}

.Signup_rightContainer__35DfO {
  display: none;
}

.Signup_leftContent__XUKh1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2.1rem;
  gap: 2.1rem;
  height: 100%;
  justify-content: center;
  padding: 0 4.8rem;
}

.Signup_formContainer__1gbc5 {
  align-items: center;
  background-color: var(--color-accent-white);
  border-radius: 12px;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  height: 64rem;
  justify-content: center;
  left: 50%;
  padding: 4.8rem 1.6rem;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 34.3rem;
}

.Signup_topContainer__2-j92 {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1.4rem;
  gap: 1.4rem;
  height: 6.4rem;
  margin-bottom: 1rem;
  padding: 0px;
  width: 31.1rem;
}

.Signup_demoNote__2HjKC {
  display: block;
  margin-bottom: 1.2rem;
}

.Signup_demoDetails__2E8M1 {
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.Signup_demoCredentials__2ioXl {
  background-color: var(--color-blue-gray-50);
  border-radius: 0.8rem;
  color: var(--color-blue-gray-500);
  font-size: 1.4rem;
  font-weight: 400;
  height: 7.8rem;
  line-height: 1.694rem;
  padding: 1.6rem 1.2rem;
  width: 31.1rem;
}
.Signup_username__Daoxt,
.Signup_password__3_49b {
  display: flex;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}

.Signup_middleContainer__2-D6O {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2.4rem;
  gap: 2.4rem;
  height: 40.8rem;
  justify-content: space-between;
  padding: 0px;
  width: 31.1rem;
}

.Signup_labels__29WdR {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  height: 16rem;
  width: 31.1rem;
}

.Signup_formContainer__1gbc5 span {
  color: var(--color-gray-700);
  display: block;
  margin-bottom: 0.6rem;
}

.Signup_formContainer__1gbc5 input {
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  color: var(--color-gray-400);
  font-size: 1.6rem;
  height: 4.4rem;
  outline: 0;
  padding: 1rem 1.4rem;
  transition: border 0.15s, box-shadow 0.15s;
  width: 31.1rem;
}

.Signup_formContainer__1gbc5 input:focus {
  border: 1px solid var(--color-blue-gray-500);
  box-shadow: 0 0 0 5px var(--color-accent);
}

.Signup_formContainer__1gbc5 input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-gray-400);
  font-size: 1.6rem;
  opacity: 1; /* Firefox */
}

.Signup_formContainer__1gbc5 input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-gray-400);
  font-size: 1.6rem;
  opacity: 1; /* Firefox */
}

.Signup_forgotPassword__FFJiB {
  text-align: right;
  width: 100%;
}

.Signup_forgotPassword__FFJiB span {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
}

.Signup_btn__1L6wG {
  background-color: var(--color-blue-gray-500);
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-accent-white);
  cursor: pointer;
  font-size: 1.6rem;
  height: 4.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.8rem;
  width: 31.1rem;
}

.Signup_bottomContainer__2Z-ux {
  height: 2.4rem;
  margin-top: 2.4rem;
  text-align: center;
  width: 100%;
}

.Signup_copyright__13Ki1 {
  color: var(--color-gray-800);
  font-size: 1.2rem;
  font-weight: 500;
}

.Signup_copyright__13Ki1 a {
  color: var(--color-gray-800);
  text-decoration: none;
  margin-left: 5px;
}

.Signup_copyright__13Ki1 a:hover {
  color: var(--color-blue-gray-300);
}

@media screen and (min-width: 1025px) {
  .Signup_logo__2oZbp {
    margin-top: 3rem;
  }
  .Signup_formContainer__1gbc5 {
    height: 67.7rem;
    width: 48rem;
  }
  .Signup_topContainer__2-j92 {
    width: 43.2rem;
  }
  .Signup_demoCredentials__2ioXl {
    display: flex;
    font-weight: 500;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    height: 4.9rem;
    justify-content: center;
    width: 43.2rem;
  }
  .Signup_middleContainer__2-D6O {
    height: 43.2rem;
    width: 43.2rem;
  }
  .Signup_labels__29WdR {
    width: 100%;
  }
  .Signup_formContainer__1gbc5 input {
    width: 100%;
  }
  .Signup_btn__1L6wG {
    width: 43.2rem;
  }
  .Signup_forgotPassword__FFJiB span {
    font-size: 1.4rem;
  }
  .Signup_pageContainer__3kS8P {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'leftContainer rightContainer';
    height: 100vh;
  }
  .Signup_leftContainer__7rKxV {
    grid-area: leftContainer;
  }
  .Signup_rightContainer__35DfO {
    background: linear-gradient(
      180deg,
      #3e4784 13.54%,
      #48508a 32.29%,
      #c6c7dd 100%
    );
    display: block;
    grid-area: rightContainer;
  }
  .Signup_rightContent__2-PVL {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .Signup_title__1NXrr {
    color: var(--color-accent-white);
    font-size: 3rem;
    font-weight: 600;
    height: 7.6rem;
    line-height: 3.8rem;
    margin-top: 9.8rem;
    width: 48.9rem;
  }
  .Signup_description__1OiPt {
    color: var(--color-accent-white);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin-top: 2.1rem;
    width: 48.9rem;
  }
  .Signup_leftContent__XUKh1 {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-gap: 2.1rem;
    gap: 2.1rem;
    height: 100%;
    justify-content: center;
    padding: 0 4.8rem;
  }
  .Signup_toolbar__DP45f {
    height: 1.856rem;
    margin-top: 8.5rem;
    width: 57.121rem;
  }
  .Signup_mockup__3DKpJ {
    border-radius: 4.28px;
    height: 37.129rem;
    margin-top: -0.1rem;
    width: 57.121rem;
  }
  .Signup_copyright__13Ki1 {
    font-size: 1.4rem;
  }
}

.Policy_container__2etgn {
  background-color: var(--color-blue-gray-200);
  padding: 3rem;
}

.Policy_container__2etgn p {
  margin: 2rem;
}

.Policy_container__2etgn li {
  margin: 2rem;
}

.Policy_container__2etgn a {
  color: blue;
}

@media screen and (min-width: 768px) {
  .Policy_container__2etgn {
    padding: 10rem;
  }
}

.Pipeline_pageContainer__3UrNI {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Pipeline_sidebar__JvNvB {
  display: none;
}

.Pipeline_mainContent__2E1iY {
  margin: 1.6rem auto 0 0;
  width: 95vw;
}

.Pipeline_dealsContainer__ASS3Q {
  display: grid;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  grid-auto-columns: minmax(1fr, 1fr);
  grid-auto-flow: column;
  margin-left: 1.6rem;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  padding-right: 1.6rem;
  width: 100%;
}

.Pipeline_dealsContainer__ASS3Q::-webkit-scrollbar {
  display: none;
}

.Pipeline_scrollControl__1_HfC {
  display: none;
}

.Pipeline_pageTitle__39fqE {
  color: var(--color-gray-900);
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.8rem;
  margin-left: 1.6rem;
  width: 34.3rem;
}

.Pipeline_dealsSummary__3Pgjr {
  color: var(--color-gray-500);
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  grid-gap: 8px;
  gap: 8px;
  line-height: 2.8rem;
  margin-left: 1.6rem;
  margin-top: 1.2rem;
  width: 34.3rem;
}

.Pipeline_dealsInfo__J4zxi {
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  align-items: center;
}

.Pipeline_footer__1Zlqs {
  min-height: 100vh;
  width: 100vw;
}

@media screen and (min-width: 415px) {
  .Pipeline_pageContainer__3UrNI {
    display: grid;
    grid-template: 10rem auto 5rem / auto 1fr;
    grid-template-areas:
      'sidebar header'
      'sidebar  main'
      'sidebar footer';
    height: 100vh;
    width: auto;
  }
  .Pipeline_sidebar__JvNvB {
    display: block;
    grid-area: sidebar;
  }
  .Pipeline_topbar__3RjNI {
    grid-area: header;
  }
  .Pipeline_mainContent__2E1iY {
    grid-area: main;
    width: 79vw;
  }
  .Pipeline_dealsContainer__ASS3Q {
    grid-gap: 2.4rem;
    gap: 2.4rem;
    margin-left: 3.2rem;
  }
  .Pipeline_pageTitle__39fqE {
    font-size: 3.6rem;
    margin-left: 3.2rem;
    width: 64.3rem;
  }
  .Pipeline_dealsSummary__3Pgjr {
    display: flex;
    justify-content: space-between;
    margin-left: 3.2rem;
    width: 100%;
  }
  .Pipeline_scrollControl__1_HfC {
    align-items: center;
    cursor: pointer;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    height: 4.2rem;
    margin-right: 3rem;
    width: 9.4rem;
  }
  .Pipeline_footer__1Zlqs {
    grid-area: footer;
    min-height: auto;
    width: 100%;
  }
}

.DealsForm_formContainer__ZD5Kk {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.DealsForm_formContainer__ZD5Kk form {
  display: flex;
  flex-direction: column;
  grid-gap: 2.4rem;
  gap: 2.4rem;
  width: 100%;
}

.DealsForm_formContainer__ZD5Kk span {
  color: var(--color-gray-700);
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  height: 2rem;
  line-height: 2rem;
  margin-bottom: 5px;
  width: 100%;
}

.DealsForm_formContainer__ZD5Kk input,
.DealsForm_dropDownMenu__wilCx {
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-gray-400);
  font-size: 1.6rem;
  font-weight: 400;
  height: 4.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.4rem;
  width: 100%;
}

.DealsForm_formContainer__ZD5Kk .DealsForm_footer__3VMHS {
  align-items: center;
  border-top: 1px solid var(--color-gray-200);
  display: flex;
  height: 7.6rem;
  justify-content: space-between;
  padding-top: 2.5rem;
  width: 100%;
}

.DealsForm_formContainer__ZD5Kk .DealsForm_footer__3VMHS .DealsForm_cancel__2nX5h {
  color: var(--color-blue-gray-500);
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 600;
  height: 4.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.8rem;
  width: 14.15rem;
}

.DealsForm_formContainer__ZD5Kk .DealsForm_footer__3VMHS .DealsForm_confirm__2LAyO {
  background-color: var(--color-blue-gray-500);
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-accent-white);
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 600;
  height: 4.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.8rem;
  width: 14.15rem;
}

@media screen and (min-width: 415px) {
  .DealsForm_formContainer__ZD5Kk .DealsForm_footer__3VMHS .DealsForm_cancel__2nX5h {
    width: 22.15rem;
  }
  .DealsForm_formContainer__ZD5Kk .DealsForm_footer__3VMHS .DealsForm_confirm__2LAyO {
    width: 22.15rem;
  }
}

.NavMenu_container__1GJK5 {
  align-items: center;
  background-color: var(--color-blue-gray-500);
  display: flex;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  height: 7.5rem;
  justify-content: space-between;
  padding: 1.6rem;
  width: 100%;
}

.NavMenu_openedMenu__2sh52 {
  background-color: var(--color-blue-gray-500);
  height: 100vh;
  width: 100vw;
}

.NavMenu_topMenu__nwpVE {
  align-items: center;
  background-color: var(--color-blue-gray-500);
  display: flex;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  height: 7.5rem;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  padding: 1.6rem;
  width: 100%;
}

.NavMenu_menuItems__HpecR {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  height: auto;
  padding: 0 1.6rem;
  width: 100%;
}

.NavMenu_linkContainer__1nImi {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  height: 5.6rem;
  line-height: 2.4rem;
  padding: 1.6rem;
  width: 34.3rem;
}

.NavMenu_linkContainer__1nImi a {
  color: var(--color-accent-white);
}

.NavMenu_bottomContainer__1RB29 {
  background-color: var(--color-gray-25);
  border-radius: 12px;
  bottom: 4.8rem;
  display: flex;
  flex-direction: column;
  height: 13.5rem;
  justify-content: center;
  left: 50%;
  padding: 1.2rem 2.4rem;
  position: fixed;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 34.3rem;
}

.NavMenu_userContainer__1Jilv {
  align-items: center;
  color: var(--color-gray-900);
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  grid-gap: 1rem;
  gap: 1rem;
  line-height: 2.4rem;
  padding: 0 1.2rem;
}

.NavMenu_signoutContainer__3RaYy {
  align-items: center;
  display: flex;
  grid-gap: 0.6rem;
  gap: 0.6rem;
  padding: 1.2rem;
}

.NavMenu_btn__1JG2d {
  background-color: var(--color-gray-25);
  border: none;
  color: var(--color-accent-red);
}

@media screen and (min-width: 415px) {
  .NavMenu_container__1GJK5 {
    display: none;
  }
  .NavMenu_openedMenu__2sh52 {
    display: none;
  }
}

.Sidebar_container__2hEyU {
  align-items: center;
  background-color: var(--color-blue-gray-600);
  display: flex;
  flex-direction: column;
  grid-gap: 2.4rem;
  gap: 2.4rem;
  height: 100%;
  min-height: 100vh;
  width: 22.9rem;
}

.Sidebar_logo__2_P-_ {
  margin: 3.2rem 1.4rem;
}

.Sidebar_menuItems__3-m2M {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  height: auto;
  padding: 0 1.6rem;
  width: 100%;
}

.Topbar_container__3cTHU {
  align-items: center;
  background-color: var(--color-accent-white);
  display: flex;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  height: 7.2rem;
  justify-content: space-evenly;
  padding: 1.6rem;
  width: 100%;
}

.Topbar_buttonContainer__1SIAd {
  align-items: center;
  background-color: var(--color-blue-gray-500);
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  height: 4rem;
  justify-content: center;
  padding: 1rem 1.8rem;
  width: 13.2rem;
}

button {
  background: none;
  border: none;
  color: var(--color-accent-white);
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 600;
  height: 2rem;
  line-height: 2rem;
  width: 7.2rem;
}

.SearchDeals_container__2HDIQ {
  align-items: center;
  background-color: var(--color-accent-white);
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  height: 4rem;
  padding-left: 1rem;
  padding: 1rem 1.4rem;
  width: 20.5rem;
}

.SearchDeals_container__2HDIQ input {
  border: none;
  box-shadow: none;
  color: var(--color-gray-400);
  width: 15.1rem;
}

.SearchDeals_container__2HDIQ input:focus {
  border: none;
  outline: 0;
  transition: border 0.15s, box-shadow 0.15s;
}

@media screen and (min-width: 415px) {
  .SearchDeals_container__2HDIQ {
    width: 56rem;
  }
}

.UserInfo_container__X9_gn {
  display: none;
}

@media screen and (min-width: 415px) {
  .UserInfo_container__X9_gn {
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    cursor: pointer;
    display: flex;
    font-weight: 600;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    height: 5.6rem;
    max-width: 32rem;
    padding: 1.2rem 2.4rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

footer {
  align-items: center;
  background-color: var(--color-gray-200);
  bottom: 0;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  height: 3.9rem;
  justify-content: center;
  left: 0;
  padding: 1.2rem 2.4rem;
  position: fixed;
  width: 100%;
}

.Footer_copyright__28fbr {
  color: var(--color-gray-800);
  font-size: 1.2rem;
  font-weight: 500;
}

.Footer_copyright__28fbr a {
  color: var(--color-gray-800);
  text-decoration: none;
  margin-left: 5px;
}

.Footer_copyright__28fbr a:hover {
  color: var(--color-blue-gray-300);
}

@media screen and (min-width: 768px) {
  footer {
    height: 4.1rem;
  }
  .Footer_copyright__28fbr {
    font-size: 1.4rem;
  }
}

.Modal_modalBackdrop__1xorG {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.Modal_modal__2n0zs {
  background: var(--color-accent-white);
  border-radius: 1.2rem;
  height: 100vh;
  left: 50%;
  padding: 3rem;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100vw;
}

.Modal_modalContent__1ATS_ {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: auto;
}

.Modal_iconContainer__wv_7m {
  align-items: center;
  border-bottom: 1px solid var(--color-gray-100);
  cursor: pointer;
  display: flex;
  height: 5.6rem;
  justify-content: space-between;
  margin-bottom: 3rem;
  width: 100%;
}

.Modal_title__2VeSv {
  color: var(--color-gray-800);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.1rem;
}

/* Smaller screens */

@media screen and (max-width: 415px) and (max-height: 680px) {
  .Modal_modalContent__1ATS_ {
    height: 50rem;
  }
}

/* Larger Screens */

@media screen and (min-width: 415px) and (min-height: 680px) {
  .Modal_modal__2n0zs {
    width: 50.3rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Modal_modalContent__1ATS_ {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 50.3rem;
  }
}

@media screen and (min-width: 415px) and (max-height: 680px) {
  .Modal_modal__2n0zs {
    width: 50.3rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Modal_modalContent__1ATS_ {
    height: 50rem;
    max-width: 50.3rem;
  }
}

.DealsStage_container__3lAA_ {
  width: 28.6rem;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
}

.DealsStage_summary__3Dp17 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.6rem;
  gap: 0.6rem;
}

.DealsStage_stageInfo__2Lup9 {
  display: flex;
  grid-gap: 0.6rem;
  gap: 0.6rem;
  align-items: center;
}

.DealsStage_stageName__3HluW {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;
  color: var(--color-gray-900);
}

.DealsStage_quantity__a2zZD {
  padding: 0.2rem 0.4rem;
  width: 1.9rem;
  height: 1.9rem;
  background-color: var(--color-gray-300);
  border-radius: 3.4rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.452rem;
  color: var(--color-gray-700);
  display: flex;
  align-items: center;
  justify-content: center;
}

.DealsStage_total__3IxH0 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--color-gray-500);
}

.DealsStage_total__3IxH0 span {
  font-weight: 600;
}

.DealsStage_cardGood__QhhR2,
.DealsStage_cardInProgress__38Qay,
.DealsStage_cardDeclined__3HZoR {
  width: 25.4rem;
  height: 15.1rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  background-color: var(--color-accent-white);
  border-radius: 0.6rem;
}

.DealsStage_cardGood__QhhR2 {
  border-top: 3px solid var(--color-success-500);
}

.DealsStage_cardInProgress__38Qay {
  border-top: 3px solid var(--color-blue-gray-400);
}

.DealsStage_cardDeclined__3HZoR {
  border-top: 3px solid var(--color-accent-red);
}

.DealsStage_cardTop__3Zj2R {
  display: flex;
  flex-direction: column;
}

.DealsStage_dealName__32vfc {
  width: 100%;
  height: 2.4rem;
  color: var(--color-gray-900);
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.2rem;
}

.DealsStage_dealClient__28Osy {
  width: 100%;
  height: 3.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--color-gray-500);
  margin-bottom: 1.2rem;
}

.DealsStage_cardBottom__2Y5FO {
  display: flex;
  height: 3.1rem;
  width: 100%;
  justify-content: space-between;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  align-items: center;
}

.DealsStage_dealValue__2FIJq {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.DealsStage_inProgress__7zigF,
.DealsStage_success__1BT-c,
.DealsStage_declined__3NxNB {
  align-items: center;
  border-radius: 1.2rem;
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  height: 3.1rem;
  justify-content: center;
  line-height: 1.452rem;
  padding: 0.8rem 1.2rem;
  width: 9.9rem;
}

.DealsStage_inProgress__7zigF {
  background-color: var(--color-gray-100);
  color: var(--color-gray-500);
}

.DealsStage_success__1BT-c {
  background-color: var(--color-success-100);
  color: var(--color-success-500);
}

.DealsStage_declined__3NxNB {
  background-color: var(--color-error-100);
  color: var(--color-accent-red);
}

@media screen and (min-width: 415px) {
  .DealsStage_container__3lAA_ {
    grid-gap: 2.4rem;
    gap: 2.4rem;
    padding: 2.4rem;
    width: 30.2rem;
  }
}

.Deals_pageContainer__3bed3 {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Deals_sidebar__1Ljoo {
  display: none;
}

.Deals_mainContent__3fZoD {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1.6rem auto 0 auto;
  width: 34.3rem;
}

.Deals_pageTitle__3Lzst {
  color: var(--color-gray-900);
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.8rem;
  width: 34.3rem;
}

.Deals_dealsSummary__3R0Ij {
  color: var(--color-gray-500);
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  grid-gap: 8px;
  gap: 8px;
  line-height: 2.8rem;
  margin-top: 1.2rem;
  width: 34.3rem;
}

.Deals_footer__2Z9ZJ {
  min-height: 100vh;
  width: 100vw;
}

@media screen and (min-width: 415px) {
  .Deals_pageContainer__3bed3 {
    display: grid;
    grid-template: 10rem auto 5rem / auto 1fr;
    grid-template-areas:
      'sidebar header'
      'sidebar  main'
      'sidebar footer';
    height: 100vh;
    width: auto;
  }
  .Deals_sidebar__1Ljoo {
    display: block;
    grid-area: sidebar;
  }
  .Deals_topbar__2XKYQ {
    grid-area: header;
  }
  .Deals_mainContent__3fZoD {
    grid-area: main;
    width: 64.3rem;
  }
  .Deals_pageTitle__3Lzst {
    font-size: 3.6rem;
    width: 64.3rem;
  }
  .Deals_dealsSummary__3R0Ij {
    width: 64.3rem;
  }
  .Deals_footer__2Z9ZJ {
    grid-area: footer;
    min-height: auto;
    width: 100%;
  }
}

.DealsList_container__1Micm {
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
}

.DealsList_paginationContainer__3_K3G {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
  width: auto;
}

.DealsList_card__1t0ZN {
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  height: 31.1rem;
  margin: auto;
  width: 34.3rem;
}

.DealsList_cardTop__2owkj {
  align-items: center;
  background-color: var(--color-blue-gray-100);
  display: flex;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  height: 12.6rem;
  padding: 1.6rem;
  width: 100%;
}

.DealsList_cardTop__2owkj input {
  width: 20rem;
}

.DealsList_titles__3tINo {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  height: 9.4rem;
  width: 21.5rem;
}

.DealsList_dealName__18vDs {
  color: var(--color-gray-900);
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
}

.DealsList_clientName__2lRAz {
  color: var(--color-gray-900);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.DealsList_icons__1KzKE {
  align-items: center;
  cursor: pointer;
  display: flex;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  height: 4.4rem;
  justify-content: center;
  width: 8rem;
}

.DealsList_buttonsContainer__1NlvB {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  height: 100%;
  justify-content: baseline;
  width: 15rem;
}

.DealsList_cancelBtn__2WRBx,
.DealsList_saveBtn__2ws10,
.DealsList_yesBtn__132AL,
.DealsList_noBtn__aSvVN {
  background: none;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-accent-white);
  cursor: pointer;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 600;
  height: 3rem;
  line-height: 2rem;
  width: 7.2rem;
}

.DealsList_cancelBtn__2WRBx,
.DealsList_noBtn__aSvVN {
  background-color: var(--color-gray-400);
}

.DealsList_saveBtn__2ws10,
.DealsList_yesBtn__132AL {
  background-color: var(--color-blue-gray-500);
}

input {
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-gray-400);
  font-size: 1.6rem;
  font-weight: 400;
  height: 2.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.4rem;
  width: 15rem;
}

.DealsList_cardBottom__3-uN3 {
  display: flex;
  flex-direction: column;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  padding: 1.6rem;
}

.DealsList_blockA__2Y06q {
  display: flex;
  flex-direction: column;
  grid-gap: 1.6rem;
  gap: 1.6rem;
}

.DealsList_blockB__3qlG_ {
  display: flex;
  flex-direction: column;
  grid-gap: 1.6rem;
  gap: 1.6rem;
}

.DealsList_content__2zGxe {
  align-items: center;
  display: flex;
  grid-gap: 1.3rem;
  gap: 1.3rem;
  height: 2.4rem;
  width: 31.1rem;
}

.DealsList_colOne__3Xm9T {
  width: 14.4rem;
}

.DealsList_colTwo__RRJDe {
  width: auto;
}

.DealsList_colTwoEdit__XvrbT {
  width: 25rem;
}

.DealsList_colTwoEdit__XvrbT input {
  width: 100%;
}

.DealsList_dealInfo__1JaSP,
.DealsList_dealValue__1o-UI,
.DealsList_dealStage__3UWhz,
.DealsList_dealCreatedDate__3KaOa {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.DealsList_dealInfo__1JaSP {
  color: var(--color-gray-500);
  font-weight: 400;
  width: 14.4rem;
}

.DealsList_dealValue__1o-UI {
  font-weight: 600;
  color: var(--color-gray-900);
  width: 8.7rem;
}

.DealsList_inProgress__35FoN,
.DealsList_success__1K4YZ,
.DealsList_declined__FUW77 {
  align-items: center;
  border-radius: 12px;
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  height: 3.1rem;
  justify-content: center;
  line-height: 1.452rem;
  padding: 8px 12px;
  width: 9.9rem;
}

.DealsList_inProgress__35FoN {
  background-color: var(--color-gray-100);
  color: var(--color-gray-500);
}

.DealsList_success__1K4YZ {
  background-color: var(--color-success-100);
  color: var(--color-success-500);
}

.DealsList_declined__FUW77 {
  background-color: var(--color-error-100);
  color: var(--color-accent-red);
}

.DealsList_dealStage__3UWhz {
  color: var(--color-gray-900);
  font-weight: 600;
}

.DealsList_dealCreatedDate__3KaOa {
  color: var(--color-gray-900);
  font-weight: 400;
}

.DealsList_deleteDealAlert__mwFFV {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  height: 10rem;
  margin-bottom: 2rem;
}

.DealsList_deleteDeal__qqmuO {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
}

.DealsList_tip__1Iyjk {
  background-color: var(--color-accent-yellow);
  border-radius: 8px;
  color: var(--color-accent-white);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 100%;
}

@media screen and (min-width: 415px) {
  .DealsList_container__1Micm {
    margin-top: 2.4rem;
  }
  .DealsList_card__1t0ZN {
    height: 20.1rem;
    width: 64.3rem;
  }
  .DealsList_cardTop__2owkj {
    height: 9.6rem;
    width: 100%;
  }
  .DealsList_titles__3tINo {
    height: 6.4rem;
    width: 49.9rem;
  }
  .DealsList_icons__1KzKE {
    grid-gap: 2.4rem;
    gap: 2.4rem;
    height: 4.8rem;
    width: 9.6rem;
  }
  .DealsList_buttonsContainer__1NlvB {
    flex-direction: row;
  }
  .DealsList_cardBottom__3-uN3 {
    flex-direction: row;
    justify-content: space-between;
    padding: 1.6rem;
  }
  .DealsList_content__2zGxe {
    width: auto;
  }
  .DealsList_colTwoEdit__XvrbT {
    width: 25rem;
  }
  .DealsList_cardTop__2owkj input {
    width: 30rem;
  }
  .DealsList_blockA__2Y06q,
  .DealsList_blockB__3qlG_ {
    width: 30rem;
  }
  input {
    font-size: 1.8rem;
    height: 2.8rem;
    width: 18rem;
  }
}

.PaginationMenu_container__3uum1 {
  display: flex;
  height: auto;
  justify-content: center;
  margin-top: 1.5rem;
  width: 100%;
}

.PaginationMenu_paginationButtons__15E6o {
  max-width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.PaginationMenu_pageElement__PXdOk {
  max-width: 4.2rem;
}

.PaginationMenu_paginationButtons__15E6o a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid var(--color-gray-400);
  cursor: pointer;
}

.PaginationMenu_paginationButtons__15E6o a:hover {
  color: var(--color-accent-white);
  background-color: var(--color-blue-gray-500);
}

.PaginationMenu_paginationActive__2K4EZ a {
  background-color: var(--color-blue-gray-500);
  color: var(--color-accent-white);
}

.PaginationMenu_previousButton__8dRNX,
.PaginationMenu_nextButton__2qfFp {
  background-color: var(--color-blue-gray-500);
  color: var(--color-accent-white);
}

/* Use if necessary */

/* .paginationDisabled a {
  color: var(--color-gray-50);
  background-color: var(--color-gray-50);
  border: none;
} */

.ForgotPassword_logo__3wOTx {
  height: 7.5rem;
  margin-top: 5rem;
  padding: 1.6rem;
  width: 20.1rem;
}

.ForgotPassword_leftContainer__QMFcf {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
}

.ForgotPassword_rightContainer__1Sxos {
  display: none;
}

.ForgotPassword_leftContent__YjtPP {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2.1rem;
  gap: 2.1rem;
  height: 100%;
  justify-content: center;
  padding: 0 4.8rem;
}

.ForgotPassword_formContainer__1i4SC {
  align-items: center;
  background-color: var(--color-accent-white);
  border-radius: 12px;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  height: 38rem;
  grid-gap: 2rem;
  gap: 2rem;
  left: 50%;
  padding: 5rem 1.6rem;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 34.3rem;
}

.ForgotPassword_labels__2OIWc {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  height: 16rem;
  width: 31.1rem;
}

.ForgotPassword_formContainer__1i4SC span {
  color: var(--color-gray-700);
  display: block;
  margin-bottom: 0.6rem;
}

.ForgotPassword_formContainer__1i4SC input {
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  color: var(--color-gray-400);
  font-size: 1.6rem;
  height: 4.4rem;
  outline: 0;
  padding: 1rem 1.4rem;
  transition: border 0.15s, box-shadow 0.15s;
  width: 31.1rem;
}

.ForgotPassword_formContainer__1i4SC input:focus {
  border: 1px solid var(--color-blue-gray-500);
  box-shadow: 0 0 0 5px var(--color-accent);
}

.ForgotPassword_formContainer__1i4SC input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-gray-400);
  font-size: 1.6rem;
  opacity: 1; /* Firefox */
}

.ForgotPassword_formContainer__1i4SC input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-gray-400);
  font-size: 1.6rem;
  opacity: 1; /* Firefox */
}

.ForgotPassword_forgotPassword__3MQzf {
  text-align: right;
  width: 100%;
}

.ForgotPassword_forgotPassword__3MQzf span {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
}

.ForgotPassword_btn__232uQ {
  background-color: var(--color-blue-gray-500);
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-accent-white);
  cursor: pointer;
  font-size: 1.6rem;
  height: 4.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.8rem;
  width: 100%;
}

.ForgotPassword_copyright__7xg2g {
  color: var(--color-gray-800);
  font-size: 1.2rem;
  font-weight: 500;
}

.ForgotPassword_copyright__7xg2g a {
  color: var(--color-gray-800);
  text-decoration: none;
  margin-left: 5px;
}

.ForgotPassword_copyright__7xg2g a:hover {
  color: var(--color-blue-gray-300);
}

.ForgotPassword_alerts__1kxN6 {
  margin-top: 0.7rem;
}

@media screen and (min-width: 1025px) {
  .ForgotPassword_logo__3wOTx {
    margin-top: 3rem;
  }
  .ForgotPassword_formContainer__1i4SC {
    height: 45rem;
    justify-content: space-around;
    width: 48rem;
  }
  .ForgotPassword_labels__2OIWc {
    width: 100%;
  }
  .ForgotPassword_formContainer__1i4SC input {
    width: 100%;
  }
  .ForgotPassword_forgotPassword__3MQzf span {
    font-size: 1.4rem;
  }
  .ForgotPassword_pageContainer__2d-A3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'leftContainer rightContainer';
    height: 100vh;
  }
  .ForgotPassword_leftContainer__QMFcf {
    grid-area: leftContainer;
  }
  .ForgotPassword_rightContainer__1Sxos {
    background: linear-gradient(
      180deg,
      #3e4784 13.54%,
      #48508a 32.29%,
      #c6c7dd 100%
    );
    display: block;
    grid-area: rightContainer;
  }
  .ForgotPassword_rightContent__3OP7_ {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .ForgotPassword_title__mpbVe {
    color: var(--color-accent-white);
    font-size: 3rem;
    font-weight: 600;
    height: 7.6rem;
    line-height: 3.8rem;
    margin-top: 9.8rem;
    width: 48.9rem;
  }
  .ForgotPassword_description__3XMYw {
    color: var(--color-accent-white);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin-top: 2.1rem;
    width: 48.9rem;
  }
  .ForgotPassword_leftContent__YjtPP {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-gap: 2.1rem;
    gap: 2.1rem;
    height: 100%;
    justify-content: center;
    padding: 0 4.8rem;
  }
  .ForgotPassword_toolbar__1P7Z0 {
    height: 1.856rem;
    margin-top: 8.5rem;
    width: 57.121rem;
  }
  .ForgotPassword_mockup__3-BYI {
    border-radius: 4.28px;
    height: 37.129rem;
    margin-top: -0.1rem;
    width: 57.121rem;
  }
  .ForgotPassword_copyright__7xg2g {
    font-size: 1.4rem;
  }
  .ForgotPassword_alerts__1kxN6 {
    margin-top: 1rem;
  }
}

