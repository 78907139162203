.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.sidebar {
  display: none;
}

.mainContent {
  margin: 1.6rem auto 0 0;
  width: 95vw;
}

.dealsContainer {
  display: grid;
  gap: 1.6rem;
  grid-auto-columns: minmax(1fr, 1fr);
  grid-auto-flow: column;
  margin-left: 1.6rem;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  padding-right: 1.6rem;
  width: 100%;
}

.dealsContainer::-webkit-scrollbar {
  display: none;
}

.scrollControl {
  display: none;
}

.pageTitle {
  color: var(--color-gray-900);
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.8rem;
  margin-left: 1.6rem;
  width: 34.3rem;
}

.dealsSummary {
  color: var(--color-gray-500);
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  gap: 8px;
  line-height: 2.8rem;
  margin-left: 1.6rem;
  margin-top: 1.2rem;
  width: 34.3rem;
}

.dealsInfo {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}

.footer {
  min-height: 100vh;
  width: 100vw;
}

@media screen and (min-width: 415px) {
  .pageContainer {
    display: grid;
    grid-template: 10rem auto 5rem / auto 1fr;
    grid-template-areas:
      'sidebar header'
      'sidebar  main'
      'sidebar footer';
    height: 100vh;
    width: auto;
  }
  .sidebar {
    display: block;
    grid-area: sidebar;
  }
  .topbar {
    grid-area: header;
  }
  .mainContent {
    grid-area: main;
    width: 79vw;
  }
  .dealsContainer {
    gap: 2.4rem;
    margin-left: 3.2rem;
  }
  .pageTitle {
    font-size: 3.6rem;
    margin-left: 3.2rem;
    width: 64.3rem;
  }
  .dealsSummary {
    display: flex;
    justify-content: space-between;
    margin-left: 3.2rem;
    width: 100%;
  }
  .scrollControl {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    height: 4.2rem;
    margin-right: 3rem;
    width: 9.4rem;
  }
  .footer {
    grid-area: footer;
    min-height: auto;
    width: 100%;
  }
}
