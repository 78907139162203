.container {
  background-color: var(--color-blue-gray-200);
  padding: 3rem;
}

.container p {
  margin: 2rem;
}

.container li {
  margin: 2rem;
}

.container a {
  color: blue;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 10rem;
  }
}
