.container {
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
}

.paginationContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
  width: auto;
}

.card {
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  height: 31.1rem;
  margin: auto;
  width: 34.3rem;
}

.cardTop {
  align-items: center;
  background-color: var(--color-blue-gray-100);
  display: flex;
  gap: 1.6rem;
  height: 12.6rem;
  padding: 1.6rem;
  width: 100%;
}

.cardTop input {
  width: 20rem;
}

.titles {
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 9.4rem;
  width: 21.5rem;
}

.dealName {
  color: var(--color-gray-900);
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
}

.clientName {
  color: var(--color-gray-900);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.icons {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 1.6rem;
  height: 4.4rem;
  justify-content: center;
  width: 8rem;
}

.buttonsContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
  justify-content: baseline;
  width: 15rem;
}

.cancelBtn,
.saveBtn,
.yesBtn,
.noBtn {
  background: none;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-accent-white);
  cursor: pointer;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 600;
  height: 3rem;
  line-height: 2rem;
  width: 7.2rem;
}

.cancelBtn,
.noBtn {
  background-color: var(--color-gray-400);
}

.saveBtn,
.yesBtn {
  background-color: var(--color-blue-gray-500);
}

input {
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--color-gray-400);
  font-size: 1.6rem;
  font-weight: 400;
  height: 2.4rem;
  line-height: 2.4rem;
  padding: 1rem 1.4rem;
  width: 15rem;
}

.cardBottom {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem;
}

.blockA {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.blockB {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.content {
  align-items: center;
  display: flex;
  gap: 1.3rem;
  height: 2.4rem;
  width: 31.1rem;
}

.colOne {
  width: 14.4rem;
}

.colTwo {
  width: auto;
}

.colTwoEdit {
  width: 25rem;
}

.colTwoEdit input {
  width: 100%;
}

.dealInfo,
.dealValue,
.dealStage,
.dealCreatedDate {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.dealInfo {
  color: var(--color-gray-500);
  font-weight: 400;
  width: 14.4rem;
}

.dealValue {
  font-weight: 600;
  color: var(--color-gray-900);
  width: 8.7rem;
}

.inProgress,
.success,
.declined {
  align-items: center;
  border-radius: 12px;
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  height: 3.1rem;
  justify-content: center;
  line-height: 1.452rem;
  padding: 8px 12px;
  width: 9.9rem;
}

.inProgress {
  background-color: var(--color-gray-100);
  color: var(--color-gray-500);
}

.success {
  background-color: var(--color-success-100);
  color: var(--color-success-500);
}

.declined {
  background-color: var(--color-error-100);
  color: var(--color-accent-red);
}

.dealStage {
  color: var(--color-gray-900);
  font-weight: 600;
}

.dealCreatedDate {
  color: var(--color-gray-900);
  font-weight: 400;
}

.deleteDealAlert {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 10rem;
  margin-bottom: 2rem;
}

.deleteDeal {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.tip {
  background-color: var(--color-accent-yellow);
  border-radius: 8px;
  color: var(--color-accent-white);
  height: fit-content;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 100%;
}

@media screen and (min-width: 415px) {
  .container {
    margin-top: 2.4rem;
  }
  .card {
    height: 20.1rem;
    width: 64.3rem;
  }
  .cardTop {
    height: 9.6rem;
    width: 100%;
  }
  .titles {
    height: 6.4rem;
    width: 49.9rem;
  }
  .icons {
    gap: 2.4rem;
    height: 4.8rem;
    width: 9.6rem;
  }
  .buttonsContainer {
    flex-direction: row;
  }
  .cardBottom {
    flex-direction: row;
    justify-content: space-between;
    padding: 1.6rem;
  }
  .content {
    width: auto;
  }
  .colTwoEdit {
    width: 25rem;
  }
  .cardTop input {
    width: 30rem;
  }
  .blockA,
  .blockB {
    width: 30rem;
  }
  input {
    font-size: 1.8rem;
    height: 2.8rem;
    width: 18rem;
  }
}
